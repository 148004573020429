import styled from "styled-components";
import { DEVICE } from "../../../../../../constants/ScreenSizes";

export const CardBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 10px;
    width: 90%;
    max-width: 700px;
`;

export const TimelineElements = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10%;
    height: 100%;
    flex: 1;
    margin-top: 35px;
`;

export const Circle = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.palette.text};
`;

export const Line = styled.div`
    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.text};
    flex-grow: 1;
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    pointer-events: "none";
    width: 90%;
    justify-content: center;
    align-items: center;
`;

export const CardImage = styled.img`
    width: 100%;
    border-radius: 10px;
    margin-bottom: 20px;
    max-width: 400px;
`;

export const CardTitle = styled.h3`
    ${({ theme }) => theme.typography.primaryTitle};
    font-size: 1.2rem;
    width: 100%;
    text-align: center;
`;

export const CardDescription = styled.p`
    ${({ theme }) => theme.typography.primaryText};
    margin-top: 10px;
    text-align: center;

    @media ${DEVICE.DESKTOP} {
        font-size: 1.2rem;
    }
`;
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonContainer, ContainerBackground, Description, InfoContainer, Title } from "./styles";
import { Header } from "../../components/Header";
import { MainButton } from "../../components/MainButton";
import { GENERAL_ROUTES } from "../../constants/routes";

export const OrderFeedback = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const status = (() => {
        if (location.pathname.includes("success")) {
            return "success";
        }

        if (location.pathname.includes("failure")) {
            return "failure";
        }

        return "pending";
    })();

    const getFailureContent = () => {
        return (
            <>
                <Title>ERRO AO FINALIZAR SUA COMPRA</Title>
                <Description><br />Parece que tivemos um problema ao processar sua compra. Por favor, tente novamente!</Description>
                <br />
                <Description>
                    Se o problema persistir, fale com os noivos no WhatsApp: <br />
                    <a
                        href="https://wa.me/5521966755666?text=Fala%2C%20Jo%C3%A3o%20Victor.%20Tive%20um%20problema%20ao%20comprar%20pelo%20site.%20Pode%20me%20ajudar%3F">
                        (21) 96675-5666
                    </a>
                </Description>
                <br />
                <ButtonContainer>
                    <MainButton onClick={() => {
                        navigate(GENERAL_ROUTES.HOME.route);
                    }} secondary>Início</MainButton>
                </ButtonContainer>
            </>
        )
    }

    const getSuccessContent = () => {
        return (
            <>
                <Title>COMPRA FINALIZADA COM SUCESSO!</Title>
                <Description><br />Obrigado por presentear os noivos! Sua compra foi finalizada com sucesso.</Description>
                <br />
                <Description>
                    Se precisar falar com a gente, entre em contato pelo WhatsApp: <br />
                    <a
                        target="_blank"
                        href="https://wa.me/5521966755666?text=Fala%2C%20Jo%C3%A3o%20Victor.%20Tive%20um%20problema%20ao%20comprar%20pelo%20site.%20Pode%20me%20ajudar%3F" rel="noreferrer">
                        (21) 96675-5666
                    </a>
                </Description>
                <br />
                <ButtonContainer>
                    <MainButton onClick={() => {
                        navigate(GENERAL_ROUTES.HOME.route);
                    }} secondary>Início</MainButton>
                </ButtonContainer>
            </>
        )
    }

    const getPendingContent = () => {
        return (
            <>
                <Title>COMPRA PENDENTE</Title>
                <Description><br />Sua compra está sendo processada. Aguarde alguns instantes.</Description>
                <br />
                <Description>
                    Se precisar falar com a gente, entre em contato pelo WhatsApp: <br />
                    <a
                        target="_blank"
                        href="https://wa.me/5521966755666?text=Fala%2C%20Jo%C3%A3o%20Victor.%20Tive%20um%20problema%20ao%20comprar%20pelo%20site.%20Pode%20me%20ajudar%3F" rel="noreferrer">
                        (21) 96675-5666
                    </a>
                </Description>
                <br />
                <ButtonContainer>
                    <MainButton onClick={() => {
                        navigate(GENERAL_ROUTES.HOME.route);
                    }} secondary>Início</MainButton>
                </ButtonContainer>
            </>
        )
    }

    const getContent = () => {
        switch (status) {
            case "success":
                return getSuccessContent();
            case "failure":
                return getFailureContent();
            default:
                return getPendingContent();
        }
    }


    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                {
                    getContent()
                }
            </InfoContainer>
        </ContainerBackground>
    );
};
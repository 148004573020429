import { Container, CustomProgressBar, HorizontalContainer, JoaoGif, MayaraGif, Title } from "./styles";
import joao from "../../../../../../assets/images/game/joao.gif";
import may from "../../../../../../assets/images/game/may.gif";

interface ProgressBarProps {
    currentStep: number;
    totalSteps: number;
}

export const ProgressBar = ({ currentStep, totalSteps }: ProgressBarProps) => {

    return (
        <Container>
            <Title>Progresso:</Title>
            <HorizontalContainer>
                <CustomProgressBar width={currentStep/totalSteps * 100}>
                    <MayaraGif src={may} alt="Mayara" width={currentStep/totalSteps * 100}/>
                </CustomProgressBar>
                <JoaoGif src={joao} alt="João "/>
            </HorizontalContainer>
        </Container>
    );
}
import React, { useEffect, useState } from "react";
import { Container, CounterContainer, DescriptionText, ItemContainer, NumberItem, Title } from "./style";


export const RegressiveCounter = () => {

    const [timeDifference, setTimeDifference] = useState(calculateTimeDifference());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeDifference(calculateTimeDifference());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function calculateTimeDifference() {
        const myMostImportantDay = new Date("2025-07-19T19:00:00");
        const today = new Date();
        return myMostImportantDay.getTime() - today.getTime();
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    const formatNumber = (number: number) => {
        return number < 10 ? `0${number}` : number;
    }

    return (
        <Container>
            <Title>CONTAGEM REGRESSIVA</Title>
            <CounterContainer>
                <ItemContainer>
                    <NumberItem>{formatNumber(days)}</NumberItem>
                    <DescriptionText>DIAS</DescriptionText>
                </ItemContainer>
                <ItemContainer>
                    <NumberItem>:</NumberItem>
                </ItemContainer>
                <ItemContainer>
                    <NumberItem>{formatNumber(hours)}</NumberItem>
                    <DescriptionText>HORAS</DescriptionText>
                </ItemContainer>
                <ItemContainer>
                    <NumberItem>:</NumberItem>
                </ItemContainer>
                <ItemContainer>
                    <NumberItem>{formatNumber(minutes)}</NumberItem>
                    <DescriptionText>MINUTOS</DescriptionText>
                </ItemContainer>
                <ItemContainer>
                    <NumberItem>:</NumberItem>
                </ItemContainer>
                <ItemContainer>
                    <NumberItem>{formatNumber(seconds)}</NumberItem>
                    <DescriptionText>SEGUNDOS</DescriptionText>
                </ItemContainer>
            </CounterContainer>
        </Container>
    )
};
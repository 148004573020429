import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;
    position: fixed;
    max-height: 80px;
    top: 0;
    left: 0;
    right: 0;
    color: ${({ theme }) => theme.palette.text};
    background-color: #54582F88;
    backdrop-filter: blur(10px);
    z-index: 1000;
`;

export const SmallLogo = styled.img`
    height: 50px;
    margin-right: 10px;
    margin-top: -3px;
    cursor: pointer;
`;

export const HeaderIcon = styled.img`
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
`;

export const InfoContainer = styled.div<OpaqueProps>`
    display: flex;
    opacity: ${({ opaque }) => opaque ? 0 : 1};
    flex: ${({ flex }) => flex};
    flex-direction: column;
    width: 100%;

    @media ${DEVICE.DESKTOP} {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

export const MobileOpenHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;

    > div {
        cursor: pointer;
    }
`;

export const HeaderItem = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    box-sizing: border-box;
    color: ${({ theme }) => theme.palette.text};
    font-size: 14px;
    text-align: center;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    @media ${DEVICE.DESKTOP} {
        justify-content: center;
        align-items: center;
        padding: 1%;
    }
`;

export const MobileInfoContainer = styled.div<OpaqueProps>`
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: row;
    opacity: ${({ opaque }) => opaque ? 0 : 1};
    flex: ${({ flex }) => flex};
`;

export const BlackMask = styled.div<IsOpenProps>`
    width: 100%;
    height: 100vh;
    background-color: #000000EF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    flex-direction: column;
    display: ${({ isOpen }) => isOpen ? 'flex' : 'none'};
`;

interface IsOpenProps {
    isOpen: boolean;
}

interface OpaqueProps {
    opaque?: boolean;
    flex: number;
}
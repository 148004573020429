import styled from "styled-components";
import { DEVICE } from "../../../../constants/ScreenSizes";

export const ContainerBackground = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    color: ${({ theme }) => theme.palette.text};
`;

export const Spacer = styled.div<SpacerProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    flex: ${({ flex }) => flex};
`;

interface SpacerProps {
    flex: number;
}

export const BibleSentence = styled.h2`
    ${({ theme }) => theme.typography.primaryTitle};
    width: 70%;
    text-align: center;
    max-width: 300px;
    margin-top: 100px;

    @media ${DEVICE.DESKTOP} {
        font-size: 1.2rem;
    }
`;

export const Author = styled.h3`
    ${({ theme }) => theme.typography.primaryText};
    margin-top: 5px;
    text-align: center;
    max-width: 300px;


    @media ${DEVICE.DESKTOP} {
        font-size: 1rem;
    }
`;

export const DescriptionText = styled.p<WidthProps>`
    ${({ theme }) => theme.typography.primaryText};
    margin-top: 20px;
    text-align: center;
    max-width: 300px;
    line-height: 1.5;
    width: ${({ width }) => width + "px" || "100%"};

    @media ${DEVICE.DESKTOP} {
        font-size: 1.1rem;
        max-width: 500px;
        width: ${({ width }) => width ? + 300 + "px" : "100%"};
    }
`;

export interface WidthProps {
    width?: number;
}

import styled, {keyframes} from "styled-components";
export const MAIN_GAME_SPEED = 1.7;

export const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100dvh;
    background-color: ${props => props.theme.palette.primaryColor}
`;

export const ProgressContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25%;
`;

export const GameBoard = styled.div`
    width: 100%;
    height: 75%;
    border-bottom: 1vh solid ${props => props.theme.palette.emptyGreen};
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    background: linear-gradient(#87CEEB, #E0F6FF);
`;

const JumpAnimation = keyframes`
    0% {
        bottom: 0;
    }
    40% {
        bottom: 130px;
    }

    50% {
        bottom: 130px;
    }

    65% {
        bottom: 130px;
    }

    100% {
        bottom: 0;
    }
`;

const CloudsAnimation = keyframes`
    0% {
        right: -250px; //Mesmo tamanho da largura da nuvem
    }
    100% {
        right: 100%;
    }
`;

export const ChurchAnimation = keyframes`
    0% {
        right: -230px; //Mesmo tamanho da largura da igreja
    }
    100% {
        right: 35%;
    }
`;

export const GroomAnimation = keyframes`
    0% {
        right: -230px; //Mesmo tamanho da largura do noivo
    }
    100% {
        right: 35%;
    }
`;


const ObstacleAnimation = keyframes`
    0% {
        right: -90px; //Mesmo tamanho da largura do obstáculo
    }
    100% {
        right: 100%;
    }
`;

export const Obstacle = styled.img<{ gameIsRunning: boolean }>`
    width: 90px;
    height: 50px;
    position: absolute;
    bottom: -5px;
    right: ${props => props.gameIsRunning ? "none" : "-90px"};
    animation: ${props => props.gameIsRunning ? ObstacleAnimation : "none"} ${MAIN_GAME_SPEED}s linear infinite;
`;

export const Clouds = styled.img<{ gameIsRunning: boolean }>`
    width: 250px;
    height: 100px;
    position: absolute;
    top: 50px;
    right: ${props => props.gameIsRunning ? "none" : "-250px"};
    animation: ${props => props.gameIsRunning ? CloudsAnimation : "none"} 8s linear infinite;
`;

export const Bride = styled.img<{ isJumping: boolean }>`
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: -10px;
    animation: ${props => props.isJumping ? JumpAnimation : "none"} 0.5s ease-out;
`;

export const Church = styled.img<{ winTheGame: boolean }>`
    width: 230px;
    height: 330px;
    bottom: -20px;
    position: absolute;
    right: -230px;
    animation: ${props => props.winTheGame ? ChurchAnimation : "none"} ${MAIN_GAME_SPEED}s linear;
`;

export const Groom = styled.img<{ winTheGame: boolean }>`
    width: 50px;
    height: 150px;
    bottom: -5px;
    position: absolute;
    right: -50px;
    animation: ${props => props.winTheGame ? GroomAnimation : "none"} ${MAIN_GAME_SPEED}s linear;
`;



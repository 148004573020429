import React from "react";
import { CardBackground, CardContainer, CardDescription, CardImage, CardTitle } from "./styles";

export interface CardItemProps {
    title: string,
    description: string | undefined,
    image: string | undefined
}

export const CardItem = ({ title, description, image }: CardItemProps) => {

    return (
        <CardBackground>
            {/* <TimelineElements>
                <Circle />
                <Line/>
            </TimelineElements> */}
            <CardContainer>
                <CardTitle>{title}</CardTitle>
                {description && <CardDescription>{description}</CardDescription>}
                {image && <CardImage src={image} alt={title} />}
            </CardContainer>
        </CardBackground>
    );
}
import { api } from ".";
import { GiftBuyerOrder } from "../../types/api/giftOrder";


export const saveGiftOrder = (giftOrder: GiftBuyerOrder) => {
    return new Promise<void>((resolve, reject) => {
        api.post("/giftOrder", {
            ...giftOrder
        }).then(() => resolve()).catch((error) => reject(error));
    });
}
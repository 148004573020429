import styled from "styled-components";

export const BackgroundContainer = styled.div<BackgroundContainerProps>`
    width: 100vw;
    height: 100vh;
    background: ${(props) => props.theme.palette.modalBackground};
    display: ${(props) => props.isVisible ? "flex" : "none"};
    position: absolute;
    z-index: 100;
    justify-content: center;
    align-items: center;
`;

export const ContentContainer = styled.div`
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.palette.text};
    gap: 8px;
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    flex-direction: column;
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 0;
    color: ${(props) => props.theme.palette.primaryColor};
`;

export const Description = styled.p`
    ${(props) => props.theme.typography.primaryText};
    font-size: 16px;
    text-align: center;
    color: ${(props) => props.theme.palette.primaryColor};
`;

export const InstructionsGoodImage = styled.img`
    width: 30%;
    border-radius: 32px;
`

export const SmallText = styled.small`
    ${(props) => props.theme.typography.primaryText};
    font-size: 12px;
    text-align: center;
    color: ${(props) => props.theme.palette.primaryColor};
    width: 60%;
`;

interface BackgroundContainerProps {
    isVisible: boolean;
}
import React, { useEffect, useState } from "react";
import { ContainerBackground } from "./styles";
import { useParams } from "react-router-dom";
import { InvitedFamily } from "../../types/api/invitedFamily";
import { getRsvpById } from "../../services/api/rsvp";

export const Rsvp = () => {

    const { id } = useParams<{ id: string }>();
    const [currentRsvp, setCurrentRsvp] = useState<InvitedFamily | null>(null);
    const [findRsvpError, setFindRsvpError] = useState<string | null>(null);

    useEffect(() => {
        if(!id) {
            setFindRsvpError("No id provided");
            return;
        }

        getRsvpById(id).then((response) => {
            console.log("OPAAA, peguei esse convite:", response);
            setCurrentRsvp(response);
        }).catch((error) => {
            setFindRsvpError(error.message);
        });
    }, [id]);


    return (
        <ContainerBackground>
            <h1>Rsvp</h1>
        </ContainerBackground>
    )
};
import React from "react";
import { BottomInfos, CenterInfos, ContainerBackground, Logo } from "./styles";
import logo from "../../../../assets/images/logo.png";
import { EndSectionLine } from "../../../../components/EndSectionLine";


export const NamePresentation = () => {

    return (
        <ContainerBackground>
            <CenterInfos>
                <h3>O INÍCIO DO NOSSO PARA SEMPRE</h3>
                <Logo src={logo} alt="Logo" />
                <h3>SÁBADO, 19 DE JULHO</h3>
            </CenterInfos>
            <BottomInfos>
                <EndSectionLine color="white" />
            </BottomInfos>
        </ContainerBackground>
    );
}
import { Hotel } from "../types/app/Hotel";

export const AVAILABLE_HOTELS: Hotel[] = [
    {
        name: "Promenade Prime",
        url: "https://www.booking.com/hotel/br/promenade-prime-residence-service.pt-br.html?aid=356937&label=metagha-link-MRBR-hotel-816040_dev-desktop_los-1_bw-0_dow-Saturday_defdate-1_room-0_gstadt-2_rateid-dg_aud-0_gacid-21411116873_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240817_ppt-GBd&sid=91da210c16e861ecbcec48c0a17c0b16&checkin=2025-07-19;checkout=2025-07-20;dest_id=-647470;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1723868445;srpvid=2fc11e88dfa600c1;type=total;ucfs=1&#no_availability_msg",
        description: "Localizado a 400 metros do centro da cidade de Itaboraí, o Promenade Prime Itaborai oferece piscina ao ar livre, sauna e academia. O Wi-Fi está disponível gratuitamente. Decorados em tons de terra, todos os quartos estão equipados com ar-condicionado, TV LCD com canais a cabo, frigobar, cofre, mesa de trabalho, fogão e banheiro privativo com secador de cabelo. No Promenade Itaboraí, você pode desfrutar do serviço de quarto para refeições. As salas de reuniões, os serviços de lavanderia e de passar roupas estão disponíveis por um custo adicional. A Estação Rodoviária de Itaboraí fica a 400 metros de distância. O Aeroporto Santos Dumont, no Rio de Janeiro, fica a 53 km de distância.",
        startRating: 4,
        eventDistanceInKm: 5.5,
        images: [
            "https://joaoemayara.com/hotels/promenade/01.jpg",
            "https://joaoemayara.com/hotels/promenade/02.jpg",
            "https://joaoemayara.com/hotels/promenade/03.jpg",
            "https://joaoemayara.com/hotels/promenade/04.jpg",
            "https://joaoemayara.com/hotels/promenade/05.jpg",
        ]
    },
    {
        name: "Palmier Hotel",
        url: "https://www.booking.com/hotel/br/palmier-amp-convencoes.pt-br.html?aid=356937&label=metagha-link-MRBR-hotel-1254860_dev-desktop_los-1_bw-0_dow-Saturday_defdate-1_room-0_gstadt-2_rateid-public_aud-0_gacid-21411116873_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240817_ppt-&sid=91da210c16e861ecbcec48c0a17c0b16&checkin=2025-07-19;checkout=2025-07-20;dest_id=-647470;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1723869222;srpvid=38bf2010d77501b7;type=total;ucfs=1&#no_availability_msg",
        description: "Dispondo de piscina ao ar livre e restaurante, o Palmier Hotel & Convenções está localizado nos arredores naturais de Itaborai. O café da manhã e o WiFi estão disponíveis gratuitamente. Todos os quartos dispõem de ar-condicionado, TV de tela plana a cabo e frigobar. Você pode desfrutar da vista para o jardim a partir do quarto.  No Palmier Hotel & Convenções, você vai encontrar uma academia (com 2 esteiras e uma bicicleta ergométrica). Outras comodidades oferecidas na propriedade incluem instalações para reuniões, salão de jogos e depósito para bagagem. Para sua comodidade, o estacionamento é gratuito.",
        startRating: 4,
        eventDistanceInKm: 6.8,
        images: [
            "https://joaoemayara.com/hotels/palmier/01.jpg",
            "https://joaoemayara.com/hotels/palmier/02.jpg",
            "https://joaoemayara.com/hotels/palmier/03.jpg",
            "https://joaoemayara.com/hotels/palmier/04.jpg"
        ]
    },
    {
        name: "Samba Itaboraí",
        url: "https://www.booking.com/hotel/br/ibis-rj-itaborai.pt-br.html?aid=356937&label=metagha-link-MRBR-hotel-1202768_dev-desktop_los-1_bw-0_dow-Saturday_defdate-1_room-0_gstadt-2_rateid-public_aud-0_gacid-21411116873_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20240817_ppt-Cg&sid=91da210c16e861ecbcec48c0a17c0b16&all_sr_blocks=120276801_94584733_2_1_0;checkin=2025-07-19;checkout=2025-07-20;dest_id=-647470;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;highlighted_blocks=120276801_94584733_2_1_0;hpos=1;matching_block_id=120276801_94584733_2_1_0;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;sr_pri_blocks=120276801_94584733_2_1_0__35428;srepoch=1723868842;srpvid=f7301f5154fc0128;type=total;ucfs=1&",
        description: "Situado em Itaboraí, a 34 km da Baía de Guanabara, o Samba Itaboraí oferece acomodações com jardim, estacionamento privativo gratuito, restaurante e bar. Este hotel 3 estrelas oferece recepção 24 horas e Wi-Fi gratuito. Você pode desfrutar de uma bebida na lanchonete. O hotel oferece quartos com ar-condicionado, mesa de trabalho, cofre, TV e banheiro privativo com chuveiro. As unidades dispõem de guarda-roupa. No Samba Itaboraí, você pode desfrutar de um café da manhã em estilo buffet ou americano.",
        startRating: 3,
        eventDistanceInKm: 7.7,
        images: [
            "https://joaoemayara.com/hotels/samba/01.jpg",
            "https://joaoemayara.com/hotels/samba/02.jpg",
            "https://joaoemayara.com/hotels/samba/03.jpg",
            "https://joaoemayara.com/hotels/samba/04.jpg"
        ]
    },
]
import React from 'react';
import { MainRoutes } from './routes';
import { ThemeProvider } from 'styled-components';
import { DarkTheme } from './constants/DarkTheme';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <ThemeProvider theme={DarkTheme}>
      <Toaster position='top-center' />
      <MainRoutes />
    </ThemeProvider>
  );
}

export default App;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Bride, Church, Clouds, GameBoard, Groom, MAIN_GAME_SPEED, Obstacle, ProgressContainer, ScreenContainer } from "./styles";
import obstacle1Img from "../../../../assets/images/game/stone-obstacle.png";
import obstacle2Img from "../../../../assets/images/game/stone2-obstacle.png"
import obstacle3Img from "../../../../assets/images/game/stone3-obstacle.png"
import bride from "../../../../assets/images/game/bride.gif";
import groom from "../../../../assets/images/game/groom.gif";
import clouds from "../../../../assets/images/game/clouds.png";
import church from "../../../../assets/images/game/church.webp";
import { useWithSound } from "../../../../hooks";
import bgMusic from "../../../../assets/sounds/marcha-nupcial.mp3";
import gameOver from "../../../../assets/sounds/game-over.mp3";
import { InstructionsModal } from "./Components/InstructionsModal";
import { ProgressBar } from "./Components/ProgressBar";
import { EndGameModal } from "./Components/EndGameModal";
import { useNavigate, useParams } from "react-router-dom";
import { GENERAL_ROUTES } from "../../../../constants/routes";

const SCORE_TO_WIN = 10;
const START_DELAY = 3;

export const GodparentsInvitationGame = () => {

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();

    const [instructionsModalOpen, setInstructionsModalOpen] = useState(true);
    const [endGameModalOpen, setEndGameModalOpen] = useState(false);
    const [gameIsRunning, setGameIsRunning] = useState(false);

    const [isJumping, setIsJumping] = useState(false);
    const score = useRef(0);
    const scoreUpdatedInThisLoop = useRef(false);
    const [winTheGame, setWinTheGame] = useState(false);
    const gameLoopRef = useRef<NodeJS.Timer | null>(null);
    const { playSound: playNupcialSound, pauseSound: pauseNupcialSound } = useWithSound(bgMusic);
    const { playSound: playGameOverSound } = useWithSound(gameOver);

    const [obstacleImg, setObstacleImg] = useState(obstacle1Img);
    const availableImages = [obstacle1Img, obstacle2Img, obstacle3Img];

    const obstacleRef = useRef<HTMLImageElement | null>(null);
    const brideRef = useRef<HTMLImageElement | null>(null);
    const cloudRef = useRef<HTMLImageElement | null>(null);
    const churchRef = useRef<HTMLImageElement | null>(null);
    const groomRef = useRef<HTMLImageElement | null>(null);

    const stopClouds = () => {
        if (cloudRef.current) {
            const cloudPosition = cloudRef.current.getBoundingClientRect();

            cloudRef.current.style.animation = "none";
            cloudRef.current.style.left = cloudPosition.left + "px";
        }
    }

    const stopObstacle = (leftPosition: undefined | number = undefined) => {
        if (obstacleRef.current) {
            // obstacleRef.current.style.animation = "none";
            // if (leftPosition) {
            //     obstacleRef.current.style.left = leftPosition + "px";
            //     return;
            // }
            const obstaclePosition = obstacleRef.current.getBoundingClientRect();
            obstacleRef.current.style.animation = "none";
            obstacleRef.current.style.left = obstaclePosition.left + "px";
        }
    }

    const stopChurch = () => {
        if (churchRef.current) {
            churchRef.current.style.animation = "none";
            churchRef.current.style.right = "35%";
        }
    }

    const stopGroom = () => {
        if (groomRef.current) {
            groomRef.current.style.animation = "none";
            groomRef.current.style.right = "35%";
        }
    }

    const checkLoseTheGame = () => {
        if (obstacleRef.current && brideRef.current && cloudRef.current) {
            const obstaclePosition = obstacleRef.current.getBoundingClientRect();
            const brideBottomPosition = +window.getComputedStyle(brideRef.current).bottom.replace("px", "");
            if (
                obstaclePosition.left < 120 &&
                obstaclePosition.left > 100 &&
                brideBottomPosition < 30) {
                setGameIsRunning(false);
                stopObstacle();
                stopClouds();
                pauseNupcialSound();
                playGameOverSound();
                setTimeout(() => {
                    setEndGameModalOpen(true);
                }, 1000);
                if (gameLoopRef.current) clearInterval(gameLoopRef.current);
            }
        }
    }

    const updateObstacleImage = () => {
        setTimeout(() => {
            const randomNumber = Math.floor(Math.random() * 3);
            setObstacleImg(availableImages[randomNumber])
        }, MAIN_GAME_SPEED * 130);
    }

    const updateScore = useCallback(() => {
        if (obstacleRef.current) {
            const obstaclePosition = obstacleRef.current.getBoundingClientRect();
            if (obstaclePosition.left < 0 && !scoreUpdatedInThisLoop.current) {
                score.current = score.current + 1;
                scoreUpdatedInThisLoop.current = true;
                updateObstacleImage();
            } else if (obstaclePosition.left > 0) {
                scoreUpdatedInThisLoop.current = false;
            }
        }
    }, [score]);

    const checkWinTheGame = useCallback(() => {
        if (score.current >= SCORE_TO_WIN && !winTheGame && churchRef.current && obstacleRef.current) {
            setWinTheGame(true);
            setTimeout(() => {
                stopObstacle(-90);
            }, MAIN_GAME_SPEED * 150);
            setTimeout(() => {
                if (churchRef.current && gameLoopRef.current && cloudRef.current) {
                    clearInterval(gameLoopRef.current);
                    stopChurch();
                    stopGroom();
                    stopClouds();
                    navigateToPostGameAfterDelay();
                }
            }, MAIN_GAME_SPEED * 1000);
        }
    }, [score, winTheGame])

    const navigateToPostGameAfterDelay = () => {
        setTimeout(() => {
            navigate(GENERAL_ROUTES.POST_GAME_INFOS.route  + "/" + id);
        }, START_DELAY * 1000);
    }


    useEffect(() => {
        if (gameIsRunning && !gameLoopRef.current) {
            gameLoopRef.current = setInterval(() => {
                checkLoseTheGame();
                updateScore();
                checkWinTheGame();
            }, 30);
        }

        return () => {
            if (gameLoopRef.current) clearInterval(gameLoopRef.current);
        };
    }, [gameIsRunning])



    const handleStartGame = () => {
        setInstructionsModalOpen(false);
        playNupcialSound();
        setTimeout(() => {
            setGameIsRunning(true);
        }, START_DELAY * 1000);
    };


    const jumpBride = () => {
        if (isJumping) return;
        setIsJumping(true);
        setTimeout(() => {
            setIsJumping(false);
        }, 500);
    }

    return (
        <ScreenContainer onClick={jumpBride}>
            <InstructionsModal
                isVisible={instructionsModalOpen}
                onClose={handleStartGame}
            />
            <EndGameModal
                isVisible={endGameModalOpen}
                onClose={() => window.location.reload()}
            />
            <GameBoard>
                <Church
                    ref={churchRef}
                    src={church}
                    alt="Igreja"
                    winTheGame={winTheGame}
                />
                <Groom
                    ref={groomRef}
                    src={groom}
                    alt="Noivo"
                    winTheGame={winTheGame}
                />
                <Clouds
                    ref={cloudRef}
                    src={clouds}
                    alt="Nuvens"
                    gameIsRunning={gameIsRunning}
                />
                <Obstacle
                    ref={obstacleRef}
                    src={obstacleImg}
                    alt="Obstáculo"
                    gameIsRunning={gameIsRunning}
                />
                <Bride
                    ref={brideRef}
                    src={bride}
                    alt="Noiva"
                    isJumping={isJumping}
                />
            </GameBoard>
            <ProgressContainer>
                <ProgressBar currentStep={score.current} totalSteps={SCORE_TO_WIN} />
            </ProgressContainer>
        </ScreenContainer>

    )
}
import { MainButton } from "../../../../../../components/MainButton"
import { BackgroundContainer, ContentContainer, Description, InstructionsGoodImage, SmallText, Title } from "./styles"
import goodGame from "../../../../../../assets/images/game/good_game.png"
import badGame from "../../../../../../assets/images/game/bad_game.png"

interface InstructionsModalProps {
    isVisible: boolean;
    onClose: () => void;
}

export const InstructionsModal = ({isVisible, onClose}: InstructionsModalProps) => {

    return (
        <BackgroundContainer isVisible={isVisible}>
            <ContentContainer>
                <Title>Como jogar</Title>
                <Description>O João Victor está na igreja esperando a Mayara, mas como de costume, ela atrasou.</Description>
                <SmallText>Toque na tela para que ela pule e chegue na igreja o mais rápido possível!</SmallText>
                <InstructionsGoodImage src={goodGame} />
                <SmallText>Não deixe que a Mayara bata nos obstáculos. Ela precisa chegar inteira no casamento!</SmallText>
                <InstructionsGoodImage src={badGame} />
                <MainButton onClick={onClose}>Vamos lá!</MainButton>
            </ContentContainer>
        </BackgroundContainer>
    )
}
import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: space-between;
    ${({ theme }) => theme.typography.primaryText};
`;

export const TimeLineContainer = styled.div<TimelineProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const SectionTitle = styled.div`
    ${({ theme }) => theme.typography.primaryTitle};
    text-align: center;
    margin-top: 50px;
    font-size: 1.5rem;
    margin-top: 100px;
`;

interface TimelineProps {
    screenWidth: number;
}
import styled from "styled-components";
import { DEVICE } from "../../../../constants/ScreenSizes";


export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    ${({ theme }) => theme.typography.primaryText};
`;

export const Logo = styled.img`
    width: 95%;
    max-width: 500px;
    margin-top: 20px;
    margin-bottom: 20px;

    @media ${DEVICE.DESKTOP} {
        max-width: 700px;
    }
`;

export const CenterInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex: 3;

    @media ${DEVICE.DESKTOP} {
        h3 {
            font-size: 1.2rem;
        }
    }
`;

export const BottomInfos = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
`;
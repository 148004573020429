import React, { useEffect, useState } from "react";
import { ButtonBackground, CenterText, FadeStyled, InfoBackground, MainButtonContainer, PlayListContainer, SectionTitle } from "./styles";
import { EndSectionLine } from "../../../../components/EndSectionLine";
import { SpotifyPlaylistView } from "../../../../components/SpotifyPlaylistView";
import { MessageCarousel } from "./components/MessageCarousel";
import { OutlineButton } from "../../../../components/OutlineButton";
import { GENERAL_ROUTES, HEADER_ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { getMessages } from "../../../../services/api/messages";
import { InvitedMessage } from "../../../../types/api/invitedMessage";

export const SocialPage = () => {

  const navigate = useNavigate();
  const [messages, setMessages] = useState<InvitedMessage[]>([]);

  useEffect(() => {
    getMessages()
      .then((messages) => {
        setMessages(messages);
      })
      .catch(() => {
        setMessages([]);
      });
  }, []);

  return (
    <InfoBackground id={HEADER_ROUTES.PLAYLIST.id}>
      <FadeStyled>
        <SectionTitle>NOSSA PLAYLIST</SectionTitle>
        <CenterText>De Vintage Culture a Rebelde, a música sempre esteve presente em nossas vidas. Cada melodia conta uma parte da nossa história, desde os primeiros olhares até os sorrisos compartilhados nos dias mais simples.</CenterText>
        <CenterText>Criamos uma playlist com músicas que marcaram momentos especiais do nosso relacionamento. Ouça e entre no clima!</CenterText>
        <PlayListContainer>
          <SpotifyPlaylistView />
        </PlayListContainer>
        <br />
        <br id={HEADER_ROUTES.SOCIAL.id} />
        <EndSectionLine color="terracota" />
        <SectionTitle marginTop={20}>REDES SOCIAIS</SectionTitle>
        <CenterText>Fique por dentro das novidades do nosso casamento. Acompanhe a página oficial no Instagram.</CenterText>
        <MainButtonContainer>
          <OutlineButton onClick={() => window.open("https://www.instagram.com/weddingjoaoemay?igshid=bjhzNzhpbms1bnlz", '_blank')}>Instagram</OutlineButton>
        </MainButtonContainer>

        {/* <InstagramProfileScreenshot src={instagramProfile} alt="Instagram" onClick={() => window.open("https://www.instagram.com/weddingjoaoemay?igsh=bjhzNzhpbms1bnlz", '_blank')} /> */}
        <br />
        <br id={HEADER_ROUTES.MESSAGES.id} />
        <EndSectionLine color="terracota" />
        <SectionTitle marginTop={20}>MURAL DE RECADOS</SectionTitle>
        <CenterText>Deixe uma mensagem para os noivos</CenterText>
        <MessageCarousel messages={messages} />
        <ButtonBackground>
          <OutlineButton onClick={() => { navigate(GENERAL_ROUTES.WRITE_MESSAGE.route) }}>Escrever Mensagem</OutlineButton>
        </ButtonBackground>
        <br />
        <br />
        <EndSectionLine color="terracota" />
      </FadeStyled>
    </InfoBackground>
  );
};
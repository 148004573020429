import { api } from ".";
import { Gift } from "../../types/api/gift";

export const getGiftList = async () => {
    return new Promise<Gift[]>((resolve, reject) => {
        api.get("/gifts")
            .then((response) => {
                resolve(response.data);
            }).catch((error) => reject(error));
    });
}

export const getGiftById = async (id: string) => {
    return new Promise<Gift>((resolve, reject) => {
        api.get(`/gifts?id=${id}`)
            .then((response) => {
                resolve(response.data);
            }).catch((error) => reject(error));
    });
}
import React from "react";
import { Gift } from "../../types/api/gift";
import { Container, GiftImage, GiftName, GiftPrice } from "./styles";
import { MainButton } from "../MainButton";

interface GiftItemProps {
    gift: Gift;
    onClick: () => void;
    hideButton?: boolean;
}

export const GiftItem = ({gift, onClick, hideButton}: GiftItemProps) => {

    const formatValue = (value: number) => {
        return new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    }

    return (
        <Container>
            <GiftImage src={gift.imageUrl} alt={gift.name} />
            <GiftName>{gift.name}</GiftName>
            <GiftPrice>{formatValue(gift.price)}</GiftPrice>
            {!hideButton && <MainButton onClick={onClick}>Presentear</MainButton>}
        </Container>
    );

};
import styled from "styled-components";

export const ButtonBackground = styled.button<ButtonBackgroundProps>`
    padding: 10px 20px;
    background-color: ${({ theme, secondary }) => secondary ? theme.palette.text : theme.palette.primaryColor};
    ${({ theme }) => theme.typography.secondaryTitle};
    color: ${({ theme, secondary }) => secondary ? theme.palette.primaryColor : theme.palette.text};
    border: none;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.2s;
    outline: none;
    border-radius: 8px;
`;

export const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
`;

interface ButtonBackgroundProps {
    secondary?: boolean;
}
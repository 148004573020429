import React, { useState } from "react";
import { Header } from "../../../../components/Header";
import { useParams } from "react-router-dom";
import { GODPARENTS_BY_ID } from "../../../../constants/GodParents";
import { ContainerBackground, Description, DescriptionInvite, InfosContainer, MoveButtonContainer, MovedButton, Title } from "./styles";
import { MainButton } from "../../../../components/MainButton";
import { AcceptedInfos } from "./components/AcceptedInfos";

export const PostGameInfos = () => {

    const { id } = useParams<{ id: string }>();
    const currentGodparents = GODPARENTS_BY_ID[id as keyof typeof GODPARENTS_BY_ID ?? "1"];
    const [hasAccepted, setHasAccepted] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });

    const getGodparentName = () => {
        if (currentGodparents.godfather && currentGodparents.godmother) {
            return currentGodparents.godfather + " e " + currentGodparents.godmother;
        } else if (currentGodparents.godfather) {
            return currentGodparents.godfather;
        }
        return currentGodparents.godmother;
    };

    const getDescription = () => {
        if (currentGodparents.godfather && currentGodparents.godmother) {
            return (
                <>
                    <Description>Parabéns! Vocês passaram no teste e estão aptos a entrar nessa grande missão!</Description>
                    <DescriptionInvite>Aceitam ser nossos padrinhos?</DescriptionInvite>
                </>
            );
        }

        if (currentGodparents.godfather) {
            return (
                <>
                    <Description>Parabéns! Você passou no teste e está apto a entrar nessa grande missão!</Description>
                    <DescriptionInvite>Aceita ser nosso padrinho?</DescriptionInvite>
                </>
            );
        }

        return (
            <>
                <Description>Parabéns! Você passou no teste e está apta a entrar nessa grande missão!</Description>
                <DescriptionInvite>Aceita ser nossa madrinha?</DescriptionInvite>
            </>
        );
    };

    const moveButton = () => {
        const randomTop = Math.floor(Math.random() * 150);
        const randomLeft = Math.floor(Math.random() * 100);
        setPosition({ top: randomTop, left: randomLeft });
    };

    return (
        <ContainerBackground>
            <Header />
            {
                !hasAccepted ?
                    <>
                        <InfosContainer>
                            <Title>{getGodparentName()}</Title>
                            {getDescription()}
                        </InfosContainer>

                        <MoveButtonContainer>
                            <MainButton secondary onClick={() => setHasAccepted(true)}>Sim 😀</MainButton>
                            <MovedButton
                                onClick={moveButton}
                                top={position.top}
                                left={position.left}
                            >
                                Não 😢
                            </MovedButton>
                        </MoveButtonContainer>
                    </>
                    : <AcceptedInfos singleGodparent={!currentGodparents.godfather || !currentGodparents.godmother} />
            }
        </ContainerBackground>
    );
};
import React from "react";
import { Line } from "./styled";

interface EndSectionLineProps {
    color: 'white' | 'terracota';
}

export const EndSectionLine = ({color}: EndSectionLineProps) => {

    return (
        <Line color={color} />
    );
}
import React, { useState } from "react";
import { BlackMask, Container, HeaderIcon, HeaderItem, InfoContainer, MobileInfoContainer, MobileOpenHeaderContainer, SmallLogo } from "./styles";
import logo from "../../assets/images/smallLogo.png";
import headerIcon from "../../assets/images/headerIcon.png";
import { useWindowDimensions } from "../../hooks";
import { SCREEN_SIZE } from "../../constants/ScreenSizes";
import { GENERAL_ROUTES, HEADER_ROUTES } from "../../constants/routes";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export const Header = () => {
    const { width } = useWindowDimensions();
    const [mobileHeaderIsOpen, setMobileHeaderIsOpen] = useState(false);
    const navigate = useNavigate();

    const getOpenHeader = () => {
        return (
            <BlackMask isOpen={mobileHeaderIsOpen}>
                <MobileOpenHeaderContainer>
                    <SmallLogo
                        onClick={() => {
                            window.location.href = '/'
                        }}
                        src={logo}
                        alt="Logo"
                    />
                    <div>
                        <IoMdClose
                            onClick={() => {
                                setMobileHeaderIsOpen(false)
                            }}
                            size={40}
                        />
                    </div>
                </MobileOpenHeaderContainer>
                <InfoContainer flex={10}>
                    {Object.keys(HEADER_ROUTES).map((item, index) => (
                        <HeaderItem
                            onClick={() => {
                                navigate(HEADER_ROUTES[item as keyof typeof HEADER_ROUTES].route)
                                setMobileHeaderIsOpen(false)
                            }}
                            key={index}>
                            {HEADER_ROUTES[item as keyof typeof HEADER_ROUTES].prettyName}
                        </HeaderItem>
                    ))}
                </InfoContainer>
            </BlackMask>
        )

    }

    if (width < SCREEN_SIZE.DESKTOP) {
        return (
            <Container>
                {getOpenHeader()}
                <InfoContainer flex={1}>
                    <SmallLogo
                        onClick={() => {
                            navigate(GENERAL_ROUTES.HOME.route)
                        }}
                        src={logo}
                        alt="Logo" />
                </InfoContainer>
                <MobileInfoContainer flex={10}>
                    <HeaderIcon
                        onClick={() => {
                            setMobileHeaderIsOpen(!mobileHeaderIsOpen)
                        }}
                        src={headerIcon}
                        alt="HeaderIcon" />
                </MobileInfoContainer>
            </Container>
        )
    }


    return (
        <Container>
            <InfoContainer flex={1}>
                <SmallLogo
                    onClick={() => {
                        navigate(GENERAL_ROUTES.HOME.route)
                    }}
                    src={logo}
                    alt="Logo" />
            </InfoContainer>
            <InfoContainer flex={10}>
                {Object.keys(HEADER_ROUTES).map((item, index) => (
                    <HeaderItem
                        onClick={() => {
                            navigate(HEADER_ROUTES[item as keyof typeof HEADER_ROUTES].route)
                        }}
                        key={index}>
                        {HEADER_ROUTES[item as keyof typeof HEADER_ROUTES].prettyName}
                    </HeaderItem>
                ))}
            </InfoContainer>
            <InfoContainer opaque flex={1}>
                <SmallLogo src={logo} alt="Logo" />
            </InfoContainer>
        </Container>
    )
};
import React from "react";
import { InvitedMessage } from "../../../../../../types/api/invitedMessage";
import { MessageCarouselItem } from "../MessageCarouselItem";
import { CarouselContainer } from "./styles";
import { Carousel } from "../../../../../../components/Carousel";


interface MessageCarouselProps {
    messages: InvitedMessage[];
}


export const MessageCarousel = ({ messages }: MessageCarouselProps) => {

    return (
        <CarouselContainer>
            <Carousel>
                {messages.map((message, index) => (
                    <MessageCarouselItem key={index} message={message} />
                ))}
            </Carousel>
        </CarouselContainer>
    );
}

import React from "react";
import { ContainerBackground } from "./styles";
import { NamePresentation } from "./components/NamePresentation";
import { Header } from "../../components/Header";
import { CoupleIntro } from "./components/CoupleIntro";
import { OurHistory } from "./components/OurHistory";
import { EventDetails } from "./components/EventDetails";
import { GiftList } from "./components/GiftList";
import { SocialPage } from "./components/SocialPage";

export const Home = () => {

    return (
        <ContainerBackground>
            <Header />
            <NamePresentation />
            <CoupleIntro />
            <OurHistory />
            <EventDetails />
            <GiftList />
            <SocialPage />
        </ContainerBackground>
    );
}
import React, { useEffect } from "react";
import { ContainerBackground } from "./styles";
import { useParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { MainButton } from "../../components/MainButton";

export const BlankRsvp = () => {

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        console.log(id)
    })




    return (
        <ContainerBackground>
            <Header />
            <p>Ops! Ainda estamos preparando nossa lista. Em breve você vai poder confirmar sua presença.</p>
            <MainButton secondary onClick={() => window.location.href = "/"}>Início</MainButton>
        </ContainerBackground>
    )
};
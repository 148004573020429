import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    min-height: 100dvh;
    display: flex;
    align-items: center;
    padding-top: 90px;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
    overflow: hidden;
`;


export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 32px;
    margin-bottom: 10px;
    text-align: center;
`;

export const Description = styled.p`
    ${(props) => props.theme.typography.primaryText};
    font-size: 18px;
    text-align: center;
    margin: 0 20px;
`;


export const DescriptionInvite = styled.p`
    ${(props) => props.theme.typography.primaryText};
    font-size: 26px;
    text-align: center;
    margin: 0 20px;
`;

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
    max-width: 500px;

    button {
        max-width: 80%;
        height: 50px;
    }
`;

export const MoveButtonContainer = styled.div`
    width: 90%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 32px;

    button {
        max-width: 200px;
    }
`;

export const MovedButton = styled.div<MovedButtonProps>`
    position: relative;
    top: ${(props) => props.top}px;
    left: ${(props) => props.left}px;
    width: 200px;
    color: ${(props) => props.theme.palette.text};
    font-family: "inter";
    height: 45px;
    text-align: center;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 6px;
    padding-top: 10px;
    box-sizing: border-box;
    margin-top: 10px;
    border: 2px solid ${(props) => props.theme.palette.text};
    cursor: pointer;
`;

interface MovedButtonProps {
    top: number;
    left: number;
}
import React, { useEffect, useState } from "react";
import { ButtonContainer, ContainerBackground, Description, InfoContainer, ItemsContainer, Title } from "./styles";
import { Header } from "../../components/Header";
import { MainButton } from "../../components/MainButton";
import { Gift } from "../../types/api/gift";
import { getGiftList } from "../../services/api/gifts";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../constants/routes";
import { GiftLoader } from "../../components/GiftLoader";
import { GiftItem } from "../../components/GiftItem";


export const GiftList = () => {

    const [giftList, setGiftList] = useState<Gift[] | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getGiftList().then((response) => {
            setGiftList(response.sort((a, b) => a.price - b.price));
        }).catch((error) => {
            console.error(error);
            setGiftList([]);
        });
    }, []);

    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                <Title>LISTA DE PRESENTES</Title>
                <Description>Sua presença é o melhor presente! <br /> Mas, se quiser mimar a gente, aqui estão algumas sugestões de presente:</Description>
                <br/>
                <ItemsContainer>
                    {
                        giftList === null ? 
                        new Array(6).fill(0).map((_, index) => (
                            <GiftLoader key={index} />
                        )) :
                        giftList.map((gift, index) => (
                            <GiftItem key={index} gift={gift} onClick={() => navigate(GENERAL_ROUTES.GIFT_ORDER.route + "/" + gift._id)} />
                        ))
                    }
                </ItemsContainer>
                <br/>
                <br/>
                <Description>Não encontrou o que procurava? <br />Veja nossa lista no Magalu:</Description>
                <ButtonContainer>
                    <MainButton onClick={() => {
                        window.open(process.env.REACT_APP_MAGALU_LIST, "_blank");
                    }} secondary>Ir para lista do Magalu</MainButton>
                </ButtonContainer>
            </InfoContainer>
        </ContainerBackground>
    );
};
import React from "react"
import { Header } from "../../../../components/Header"
import { useNavigate, useParams } from "react-router-dom";
import { GODPARENTS_BY_ID } from "../../../../constants/GodParents";
import { ContainerBackground, Description, InfosContainer, Title } from "./styles";
import { MainButton } from "../../../../components/MainButton";
import { GENERAL_ROUTES } from "../../../../constants/routes";

export const PreGameInfos = () => {

    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const currentGodparents = GODPARENTS_BY_ID[id as keyof typeof GODPARENTS_BY_ID ?? "1"];

    const getGodparentName = () => {
        if (currentGodparents.godfather && currentGodparents.godmother) {
            return currentGodparents.godfather + " e " + currentGodparents.godmother;
        } else if (currentGodparents.godfather) {
            return currentGodparents.godfather;
        }
        return currentGodparents.godmother;
    }

    const getDescription = () => {
        if (currentGodparents.godfather && currentGodparents.godmother) {
            return (
                <>
                    <Description>A vida é feita de desafios, e cada etapa importante traz seus obstáculos. Mas, assim como em todas as jornadas, ter amigos ao lado faz toda a diferença. Vocês foram escolhidos porque estiveram conosco nos momentos mais felizes e nas dificuldades, mostrando que a amizade é um dos maiores pilares da nossa história.</Description>
                    <Description>Agora, temos uma missão especial para vocês. E, para garantir que possuem os requisitos necessários para essa tarefa, chegou a hora de testá-los.</Description>
                    <Description>Estão prontos?</Description>
                </>
            )
        }

        return (
            <>
                <Description>A vida é feita de desafios, e cada etapa importante traz seus obstáculos. Mas, assim como em todas as jornadas, ter amigos ao lado faz toda a diferença. Você foi escolhido porque esteve conosco nos momentos mais felizes e nas dificuldades, mostrando que a amizade é um dos maiores pilares da nossa história.</Description>
                <Description>Agora, temos uma missão especial para você. E, para garantir que possui os requisitos necessários para essa tarefa, chegou a hora de testá-lo.</Description>
                <Description>Está pronto?</Description>
            </>
        )
    }

    return (
        <ContainerBackground>
            <Header />
            <InfosContainer>
                <Title>{getGodparentName()}</Title>
                {getDescription()}
                <MainButton secondary onClick={() => navigate(GENERAL_ROUTES.GODPARENTS_INVITATION_GAME.route + "/" + id)}>ACEITAR DESAFIO</MainButton>
            </InfosContainer>
        </ContainerBackground>
    )
}
import styled from "styled-components";

export const ContainerBackground = styled.div`
  background-color: ${(props) => props.theme.palette.primaryColor};
  width: 100%;
  height: 100vh;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  padding-top: 80px;
  position: relative;
  ${({ theme }) => theme.typography.primaryText};
`;

export const InfosContainer = styled.div`
    width: 80%;
    max-width: 500px;
    padding: 10px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    flex-direction: column;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    background-color: #F8FBCBBB;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    max-width: 400px;
`;

export const SubTitle = styled.div`
    ${({ theme }) => theme.typography.secondaryText};
    color: ${({ theme }) => theme.palette.secondaryColor};
    font-size: 1rem;
    font-weight: bold;
`;

export const DescriptionText = styled.p`
    ${({ theme }) => theme.typography.lightText};
    color: ${({ theme }) => theme.palette.primaryColor};
    font-size: 1rem;
    margin: 0;
`;

export const CenterText = styled(DescriptionText)`
    text-align: center;
    margin-top: 20px;
    width: 90%;
`;

export const SectionTitle = styled.div<SectionTitleProps>`
    ${({ theme }) => theme.typography.primaryTitle};
    color: ${({ theme }) => theme.palette.tertiaryColorLight};
    text-align: center;
    font-size: 1.5rem;
    margin-top: ${({ marginTop }) => marginTop ?? 150}px;
`;

export const FadeStyled = styled.div<FadeStyledProps>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: ${({ flex }) => flex};
  `;

  interface FadeStyledProps {
    flex: number;
}

interface SectionTitleProps {
    marginTop?: number;
}
import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 170px;
    height: 330px;
    margin: 5px;
    border-radius: 8px;

    @media ${DEVICE.DESKTOP} {
        width: 300px;
        height: 400px;
    }
`;
import styled from 'styled-components';

export const CarouselContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const CarouselWrapper = styled.div<{ currentSlide: number; slideWidth: number }>`
  display: flex;
  transition: transform 0.5s ease-in-out;
  transform: ${({ currentSlide, slideWidth }) => `translateX(-${currentSlide * slideWidth}%)`};
`;

export const Slide = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

export const NavigationButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

export const PrevButton = styled(NavigationButton)`
  left: 10px;
`;

export const NextButton = styled(NavigationButton)`
  right: 10px;
`;
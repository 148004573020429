import React from "react";
import { ButtonContainer, CardContainer, Description, ImageContainer, InfosContainer, RowContainer, Title, WebCardContainer, WebImageContainer } from "./styles";
import { Hotel } from "../../../../types/app/Hotel";
import { FadeSlideImage } from "../../../../components/FadeSlideImage";
import { StarRating } from "../../../../components/StarRating";
import { MainButton } from "../../../../components/MainButton";
import { useWindowDimensions } from "../../../../hooks";
import { SCREEN_SIZE } from "../../../../constants/ScreenSizes";

interface HotelCardProps {
    hotel: Hotel;
}

export const HotelCard = ({ hotel }: HotelCardProps) => {

    const { width } = useWindowDimensions();

    const formatDescription = (description: string) => {
        if (width > SCREEN_SIZE.DESKTOP) {
            return description.substring(0, 250) + '...';
        }

        return description.substring(0, 80) + '...';
    }

    if (width < SCREEN_SIZE.DESKTOP) {
        return (
            <CardContainer>
                <RowContainer>


                    <ImageContainer>
                        <FadeSlideImage
                            slides={hotel.images}
                            duration={3000}
                        />
                    </ImageContainer>
                    <InfosContainer>
                        <div>
                            <Title>{hotel.name}</Title>
                            <StarRating rating={hotel.startRating} />
                            <Description>{formatDescription(hotel.description)}</Description>
                        </div>
                    </InfosContainer>
                </RowContainer>
                <ButtonContainer>
                    <MainButton onClick={() => window.open(hotel.url, '_blank')}>Reservar</MainButton>
                </ButtonContainer>
            </CardContainer>

        )
    }

    return (
        <WebCardContainer>
            <Title>{hotel.name}</Title>
            <StarRating rating={hotel.startRating} />
            <WebImageContainer>
                <FadeSlideImage
                    slides={hotel.images}
                    duration={3000}
                />
            </WebImageContainer>
            <InfosContainer>
                <div>

                    <Description>{formatDescription(hotel.description)}</Description>
                </div>
                <ButtonContainer>
                    <MainButton onClick={() => window.open(hotel.url, '_blank')}>Reservar</MainButton>
                </ButtonContainer>
            </InfosContainer>
        </WebCardContainer>
    );
}
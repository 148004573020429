import React, { useState, ReactNode } from 'react';
import { CarouselContainer, CarouselWrapper, NextButton, PrevButton, Slide } from './styles';


interface CarouselProps {
    children: ReactNode;
}

export const Carousel: React.FC<CarouselProps> = ({ children }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const childrenArray = React.Children.toArray(children);

    const handlePrev = () => {
        setCurrentSlide((prev) => (prev === 0 ? childrenArray.length - 1 : prev - 1));
    };

    const handleNext = () => {
        setCurrentSlide((prev) => (prev === childrenArray.length - 1 ? 0 : prev + 1));
    };

    return (
        <CarouselContainer>
            <PrevButton onClick={handlePrev}>❮</PrevButton>
            <CarouselWrapper currentSlide={currentSlide} slideWidth={100}>
                {childrenArray.map((child, index) => (
                    <Slide key={index}>{child}</Slide>
                ))}
            </CarouselWrapper>
            <NextButton onClick={handleNext}>❯</NextButton>
        </CarouselContainer>
    );
};

import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;

export const InfoContainer = styled.div`
    ${({ theme }) => theme.typography.secondaryText};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
    width: 90%;
    max-width: 500px;

    p {
      font-size: 16px;
      text-align: center;
      margin-bottom: 10px;
    }

    small {
      font-size: 16px;
      width: 100%;
      margin-bottom: 10px;
    }
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
`;

export const TipsContainer = styled.div`
    ${(props) => props.theme.typography.secondaryTitle};
    display: flex;
    flex-direction: column;
    width: 90%;

    small {
      width: 100%;
      text-align: right;
    }

    p {
      margin-top: 5px;
      font-size: 14px;
      text-align: left;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    button {
      margin-bottom: 20px;
    }
`;
import React, { useState } from "react";
import { ButtonContainer, ContainerBackground, InfoContainer, TipsContainer, Title } from "./styles";
import { Header } from "../../components/Header";
import MainInput from "../../components/MainInput";
import { MainButton } from "../../components/MainButton";
import { InvitedMessage } from "../../types/api/invitedMessage";
import { EndSectionLine } from "../../components/EndSectionLine";
import { OutlineButton } from "../../components/OutlineButton";
import { useNavigate } from "react-router-dom";
import { GENERAL_ROUTES } from "../../constants/routes";
import { postMessage } from "../../services/api/messages";
import toast from "react-hot-toast";


export const Message = () => {

    const [newMessage, setNewMessage] = useState({ name: "", text: "" } as InvitedMessage);
    const [isMessageSent, setMessageSent] = useState(false);
    const [nameError, setNameError] = useState("");
    const [textError, setTextError] = useState("");
    const [textCounter, setTextCounter] = useState(0);
    const TEXT_LIMIT = 250;
    const navigate = useNavigate();

    const saveMessage = () => {
        let allRight = true;
        if (!newMessage.name) {
            setNameError("Campo obrigatório");
            allRight = false;
        }else {
            setNameError("");
        }

        if (!newMessage.text) {
            setTextError("Campo obrigatório");
            allRight = false;
        }else {
            setTextError("");
        }

        if(!allRight) return;

        postMessage(newMessage)
            .then(() => {
                setMessageSent(true);
                toast.success("Mensagem enviada com sucesso!");
            })
            .catch(() => {
                setMessageSent(false);
                toast.error("Erro ao enviar mensagem.");
            });
    };


    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                {
                    isMessageSent ?
                        <>
                            <Title>Sua mensagem foi enviada!</Title>
                            <p>Obrigado por separar um minuto do seu dia escrevendo essa mensagem. Ela será lida e guardada com muito carinho!</p>
                            <EndSectionLine color="white" />
                            <small>Continue navegando:</small>
                            <ButtonContainer>
                                <MainButton onClick={() => navigate(GENERAL_ROUTES.HOME.route)} secondary>Início</MainButton>
                                <OutlineButton onClick={() => null} secondary>Lista de presentes</OutlineButton>
                            </ButtonContainer>
                        </>
                        :
                        <>
                            <Title>Escreva uma mensagem para os noivos</Title>
                            <MainInput
                                onChange={(newName) => {
                                    if(nameError !== "") setNameError("");
                                    setNewMessage({ ...newMessage, name: newName })
                                }}
                                placeholder="Fulano da Silva"
                                type="text"
                                value={newMessage.name}
                                title="Nome"
                                errorText={nameError}
                            />
                            <MainInput
                                onChange={(newText) => {
                                    if(textError !== "") setTextError("");
                                    if(newText.length > TEXT_LIMIT) return;
                                    setNewMessage({ ...newMessage, text: newText })
                                    setTextCounter(newText.length);
                                }}
                                placeholder="Felicidades ao casal!"
                                type="text"
                                value={newMessage.text}
                                rowsNumber={4}
                                title="Sua mensagem"
                                errorText={textError}
                            />
                            <TipsContainer>
                                <small>{textCounter}/{TEXT_LIMIT}</small>
                                <p>*Seu texto ficará disponível para todos que visitarem o nosso site. </p>
                                <p>*Não é possível editar ou apagar sua mensagem.</p>
                            </TipsContainer>
                            <ButtonContainer>
                                <MainButton onClick={() => saveMessage()} secondary> Enviar</MainButton>
                            </ButtonContainer>
                        </>
                }
            </InfoContainer>
        </ContainerBackground>
    );
};
import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import styled from 'styled-components';

const MapContainer = styled.div`
  height: 300px;
  width: 100%;
`;

const MapView = ({ location }: { location: { lat: number, lng: number } }) => {
    const mapStyles = {
        height: "100%",
        width: "100%"
    };

    return (
        <MapContainer>
            <LoadScript googleMapsApiKey={process.env.REACT_APP_MAPS_API_KEY || ""}>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={15}
                    center={location}
                    options={{
                        streetViewControl: false,
                        fullscreenControl: false,
                        mapTypeControl: false,
                        rotateControl: false,
                        scaleControl: false,
                        zoomControl: false,
                        panControl: false,
                        keyboardShortcuts: false,
                        disableDefaultUI: true,
                        gestureHandling: 'none',
                        draggable: false,
                    }}
                >
                    <Marker position={location} />
                </GoogleMap>
            </LoadScript>
        </MapContainer>
    );
};

export default MapView;
import styled from "styled-components";

export const CarouselItemContainer = styled.div`
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    color: ${({ theme }) => theme.palette.primaryColor};
    padding: 50px;
`;

export const MessageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: ${({ theme }) => theme.palette.primaryColor};
    border-radius: 16px;
    color: ${({ theme }) => theme.palette.text};

    h3 {
        margin: 16px;
        ${({ theme }) => theme.typography.primaryTitle};
        margin-bottom: -10px;
    }
`;

import React, { useMemo } from "react";
import { ButtonContainer, CenterText, ContainerBackground, FadeStyled, InfosContainer, SectionTitle } from "./styles";
import { ImageSlider } from "../../../../components/ImageSlider";
import img1 from '../../../../assets/images/slider/1.jpg';
import img2 from '../../../../assets/images/slider/2.jpg';
import img4 from '../../../../assets/images/slider/4.jpg';
import img3 from '../../../../assets/images/slider/3.jpg';
import img5 from '../../../../assets/images/slider/5.jpg';
import img6 from '../../../../assets/images/slider/6.jpg';
import img7 from '../../../../assets/images/slider/7.jpg';
import img8 from '../../../../assets/images/slider/8.jpg';
import img9 from '../../../../assets/images/slider/9.jpg';
import img10 from '../../../../assets/images/slider/10.jpg';
import img11 from '../../../../assets/images/slider/11.jpg';
import img12 from '../../../../assets/images/slider/12.jpg';
import img13 from '../../../../assets/images/slider/13.jpg';
import img14 from '../../../../assets/images/slider/14.jpg';
import img15 from '../../../../assets/images/slider/15.jpg';
import img16 from '../../../../assets/images/slider/16.jpg';
import img17 from '../../../../assets/images/slider/17.jpg';
import img18 from '../../../../assets/images/slider/18.jpg';
import img19 from '../../../../assets/images/slider/19.jpg';
import img20 from '../../../../assets/images/slider/20.jpg';
import img21 from '../../../../assets/images/slider/21.jpg';
import img22 from '../../../../assets/images/slider/22.jpg';
import img23 from '../../../../assets/images/slider/23.jpg';
import img24 from '../../../../assets/images/slider/24.jpg';
import img25 from '../../../../assets/images/slider/25.jpg';
import img26 from '../../../../assets/images/slider/26.jpg';
import img27 from '../../../../assets/images/slider/27.jpg';
import img28 from '../../../../assets/images/slider/28.jpg';
import img29 from '../../../../assets/images/slider/29.jpg';
import img30 from '../../../../assets/images/slider/30.jpg';
import { useWindowDimensions } from "../../../../hooks";
import { SCREEN_SIZE } from "../../../../constants/ScreenSizes";
import { OutlineButton } from "../../../../components/OutlineButton";
import { GENERAL_ROUTES, HEADER_ROUTES } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";


export const GiftList = () => {

    const { width } = useWindowDimensions();
    const navigate = useNavigate();
    const firstLine = useMemo(() => {
        if (width < SCREEN_SIZE.DESKTOP) {
            return [img1, img2, img3, img4, img5, img6];
        } else {
            return [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10];
        }
    }, [width]);

    const secondLine = useMemo(() => {
        if (width < SCREEN_SIZE.DESKTOP) {
            return [img11, img12, img13, img14, img15, img16];
        } else {
            return [img11, img12, img13, img14, img15, img16, img17, img18, img19, img20];
        }
    }, [width]);

    const thirdLine = useMemo(() => {
        if (width < SCREEN_SIZE.DESKTOP) {
            return [img21, img22, img23, img24, img25, img26];
        } else {
            return [img21, img22, img23, img24, img25, img26, img27, img28, img29, img30];
        }
    }, [width]);

    return (
        <ContainerBackground id={HEADER_ROUTES.GIFT_LIST.id}>
            <ImageSlider images={firstLine} />
            <ImageSlider images={secondLine} reverseDirection />
            <ImageSlider images={thirdLine} />
            <InfosContainer>
                <FadeStyled flex={8}>
                    <SectionTitle marginTop={20}>LISTA DE PRESENTES</SectionTitle>
                    <CenterText>Sua presença é o melhor presente! <br/> Mas, se quiser mimar a gente, veja nossa lista.</CenterText>
                    <ButtonContainer>
                        <OutlineButton onClick={() => navigate(GENERAL_ROUTES.GIFT_LIST.route)}>
                            PRESENTES
                        </OutlineButton>
                    </ButtonContainer>
                </FadeStyled>
            </InfosContainer>
        </ContainerBackground>
    );
}
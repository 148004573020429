import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    width: 170px;
    justify-content: space-around;
    height: 350px;
    margin: 5px;
    border-radius: 8px;
    background-color: ${(props) => props.theme.palette.text};

    @media ${DEVICE.DESKTOP} {
        width: 300px;
        height: 450px;
    }
`;

export const GiftImage = styled.img`
    height: 140px;
    width: 100%;
    border-radius: 16px;
    max-height: 135px;
    background-color: ${(props) => props.theme.palette.primaryColor};
    object-fit: cover;

    @media ${DEVICE.DESKTOP} {
        height: 230px;
        max-height: 230px;
    }
`;

export const GiftName = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    font-size: 14px;
    margin-top: 10px;
    text-align: center;
    color: ${(props) => props.theme.palette.primaryColor};

    @media ${DEVICE.DESKTOP} {
        font-size: 18px;
    }
`;

export const GiftPrice = styled.h2`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 24px;
    margin-top: 0;
    text-align: center;
    color: ${(props) => props.theme.palette.tertiaryColorLight};
    font-family: "nice-bold";
`;
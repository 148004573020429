import styled from "styled-components";

export const Line = styled.div<LineProps>`
    width: 66px;
    height: 1px;
    margin: 20px 0;
    background-color: ${({ theme, color }) => color === 'white' ? theme.palette.text : theme.palette.tertiaryColorLight};

`;

interface LineProps {
    color: 'white' | 'terracota';
}
import {
	Routes,
	Route,
	BrowserRouter as Router,
} from "react-router-dom";
import { BlankRsvp, GiftList, GiftOrder, GodparentsInvitationGame, Home, HotelRecommendations, Message, OrderFeedback, Rsvp } from "../pages";
import { GENERAL_ROUTES } from "../constants/routes";
import { ScrollToTop } from "../utils/ScrollToTop";
import { PostGameInfos, PreGameInfos } from "../pages/GodparentsInvitation";

export const MainRoutes = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="*" element={<Home/>} />
                <Route path={GENERAL_ROUTES.WRITE_MESSAGE.route} element={<Message/>} />
                <Route path={GENERAL_ROUTES.GIFT_LIST.route} element={<GiftList/>} />
                <Route path={`${GENERAL_ROUTES.GIFT_ORDER.route}/:id`} element={<GiftOrder/>} />
                <Route path={`${GENERAL_ROUTES.ORDER_SUCCESS.route}`} element={<OrderFeedback/>} />
                <Route path={`${GENERAL_ROUTES.ORDER_FAILURE.route}`} element={<OrderFeedback/>} />
                <Route path={`${GENERAL_ROUTES.ORDER_PENDING.route}`} element={<OrderFeedback/>} />
                <Route path={`${GENERAL_ROUTES.HOTEL_RECOMMENDATIONS.route}`} element={<HotelRecommendations/>} />
                <Route path={`${GENERAL_ROUTES.RSVP.route}/:id`} element={<Rsvp/>} />
                <Route path={`${GENERAL_ROUTES.RSVP.route}`} element={<BlankRsvp/>} />

                <Route path={`${GENERAL_ROUTES.GODPARENTS_INVITATION_GAME.route}/:id`} element={<GodparentsInvitationGame/>} />
                <Route path={`${GENERAL_ROUTES.PRE_GAME_INFOS.route}/:id`} element={<PreGameInfos/>} />
                <Route path={`${GENERAL_ROUTES.POST_GAME_INFOS.route}/:id`} element={<PostGameInfos/>} />
            </Routes>
        </Router>
    )
}
import styled, { keyframes } from 'styled-components';
import { DEVICE } from '../../constants/ScreenSizes';

const animationTime = 30;
const animationTimeDesktop = 60;
const imgHeightVh = 25;

const slideAnimation = keyframes`
  from {
    transform: translateX(100%);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 1;
  }
`;

const reverseSlideAnimation = keyframes`
    from {
        transform: translateX(-100%);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 1;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${imgHeightVh}vh;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const Logos = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    height: ${imgHeightVh}vh;
`;

export const SecondaryLogos = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    height: ${imgHeightVh}vh;
    margin-top: -${imgHeightVh}vh;
`;

export const LogosSlide = styled.div<LogosSlideProps>`
    animation: ${(props) => (props.reverseSlide ? reverseSlideAnimation : slideAnimation)} ${animationTime}s infinite linear;
    width: 100%; 
    height: 100%;
    display: flex;
    overflow: hidden;

    @media ${DEVICE.DESKTOP} {
        animation: ${(props) => (props.reverseSlide ? reverseSlideAnimation : slideAnimation)} ${animationTimeDesktop}s infinite linear;
    }
`;

export const SecondaryLogosSlide = styled.div<LogosSlideProps>`
    animation: ${(props) => (props.reverseSlide ? reverseSlideAnimation : slideAnimation)} ${animationTime}s infinite linear;
    animation-delay: ${animationTime / 2}s;
    opacity: 0;
    width: 100%; 
    height: 100%;
    display: flex;
    overflow: hidden;

    @media ${DEVICE.DESKTOP} {
        animation: ${(props) => (props.reverseSlide ? reverseSlideAnimation : slideAnimation)} ${animationTimeDesktop}s infinite linear;
        animation-delay: ${animationTimeDesktop / 2}s;
    }
`;

interface LogosSlideProps {
    reverseSlide?: boolean;
}

export const ImageContainer = styled.div<LogoImageProps>`
    display: flex;
    width: ${(props) => props.screenWidth / props.photoCount - 10}px;
    margin-right: 10px;
    border-radius: 12px;
    position: relative;
`;

export const ImageMask = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.palette.imageMask};
    z-index: 2;
    border-radius: 12px;
`;

export const LogoImage = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    filter: grayscale(100%);
`;

interface LogoImageProps {
    photoCount: number;
    screenWidth: number;
}
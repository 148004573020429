import styled from "styled-components";

export const ContainerBackground = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    > h3 {
        ${(props) => props.theme.typography.secondaryTitle};
        padding: 24px;
        box-sizing: border-box;
        text-align: center;
    }
`;

export const RegressiveCounterContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 400px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    margin: -16px 0;
`;

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${(props) => props.theme.palette.text};
    width: 90%;
    border-radius: 16px;
    max-width: 500px;
    margin-bottom: 24px;
    margin-top: 24px;

    h4 {
        ${(props) => props.theme.typography.primaryTitle};
        color: ${(props) => props.theme.palette.primaryColor};
        font-size: 18px;
    }

    p {
        ${(props) => props.theme.typography.primaryText};
        color: ${(props) => props.theme.palette.primaryColor};
        font-size: 16px;
        margin: 0;
    }

    small {
        ${(props) => props.theme.typography.primaryText};
        color: ${(props) => props.theme.palette.primaryColor};
        font-size: 12px;
        width: 80%;
        text-align: center;
        margin-top: 8px;
    }
`;

export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px 0;
    width: 90%;
`;

export const AlexaImage = styled.img`
    width: 300px;
`;
import styled from "styled-components";
import { DEVICE } from "../../../../constants/ScreenSizes";


export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    margin: 10px 20px;
    padding: 10px;
    background-color: ${(props) => props.theme.palette.text};
`;

export const WebCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    margin: 20px;
    padding: 20px 5%;
    box-sizing: border-box;
    transition: 0.3s;
    background-color: ${(props) => props.theme.palette.text};

    &:hover {
        scale: 1.02;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
`;

export const WebImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    margin: 10px 0;
    justify-content: center;
    border-radius: 24px;
    overflow: hidden;
`;

export const InfosContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 16px;
    width: 100%;
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.secondaryTitle};
    font-size: 20px;
    color: ${(props) => props.theme.palette.tertiaryColorLight};
    margin: 0;
    font-family: "nice-bold";

    @media ${DEVICE.DESKTOP} {
        font-size: 24px;
    }
`;

export const Description = styled.p`
    ${(props) => props.theme.typography.lightText};
    font-size: 14px;
    color: ${(props) => props.theme.palette.primaryColor};
    margin: 0;

    @media ${DEVICE.DESKTOP} {
        font-size: 16px;
    }
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    margin-top: 15px;
    justify-content: center;

    button {
        width: 100%;
    }

    @media ${DEVICE.DESKTOP} {
        justify-content: end;
        margin-top: 30px;

        button {
            max-width: 200px;
        }
    }
`;
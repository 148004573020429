import React, { useState, useEffect } from 'react';
import { useTransition, animated, config } from '@react-spring/web';
import { StyledImage, Container } from './styles';

interface SlideProps {
    slides: string[];
    duration: number;
}

export const FadeSlideImage = ({ slides, duration }: SlideProps) => {
    const [index, set] = useState(0);

    const transitions = useTransition(slides[index], {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: config.molasses,
    });

    useEffect(() => {
        const interval = setInterval(() => set(state => (state + 1) % slides.length), duration);
        return () => clearInterval(interval);
    }, [slides.length, duration]);

    return (
        <Container>
            {transitions((style, item) => (
                <animated.div style={style} key={item}>
                    <StyledImage src={item} />
                </animated.div>
            ))}
        </Container>
    );
};
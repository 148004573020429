import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
`;

export const HorizontalContainer = styled.div`
    display: flex;
    width: 80%;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`

export const CustomProgressBar = styled.div<CustomProgressBarProps>`
    display: flex;
    background-color: ${props => props.theme.palette.text};
    height: 16px;
    width: ${props => props.width}%;
    border-radius: 32px;
    transition: all 0.5s ease;
    justify-content: end;
    align-items: center;
    margin-left: 5%;
    position: relative;
`

export const JoaoGif = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
`

export const MayaraGif = styled.img<CustomProgressBarProps>`
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: ${props => props.width > 80 ? "-10px" : "-30px"};
    position: absolute;
    right: 0;
`

interface CustomProgressBarProps {
    width: number;
}

import React, { useMemo } from "react";
import { FaStar } from "react-icons/fa6";
import { CiStar } from "react-icons/ci";
import { useTheme } from "styled-components";


interface StarRatingProps {
    rating: number;
}

export const StarRating = ({rating}: StarRatingProps) => {

    const emptyStars = useMemo(() =>  5 - rating, [rating]);
    const theme = useTheme();

    return (
        <div>
            {
                Array.from({length: rating}, (_, index) => (
                    <FaStar key={index} color={theme.palette.tertiaryColorLight} />
                ))
            }
            {
                Array.from({length: emptyStars}, (_, index) => (
                    <CiStar key={index} color={theme.palette.tertiaryColorLight} />
                ))
            }
        </div>
    )
}
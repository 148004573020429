import { MainButton } from "../../../../../../components/MainButton"
import { BackgroundContainer, ContentContainer, Description, SmallText, Title } from "./styles"

interface EndGameModalProps {
    isVisible: boolean;
    onClose: () => void;
}

export const EndGameModal = ({isVisible, onClose}: EndGameModalProps) => {

    return (
        <BackgroundContainer isVisible={isVisible}>
            <ContentContainer>
                <Title>Você perdeu!</Title>
                <Description>Infelizmente a Mayara bateu em um obstáculo e não conseguiu chegar a tempo na Igreja. O João Victor ainda está esperando-a no altar.</Description>
                <SmallText>Vamos mais uma vez?</SmallText>
                <MainButton onClick={onClose}>Tentar novamente</MainButton>
            </ContentContainer>
        </BackgroundContainer>
    )
}
import React, { useMemo } from 'react';
import { ImageContainer, ImageMask, LogoImage, Logos, LogosSlide, MainContainer, SecondaryLogos, SecondaryLogosSlide } from './styles';
import { useWindowDimensions } from '../../hooks';

interface ImageSliderProps {
    images: string[];
    reverseDirection?: boolean;
}

export const ImageSlider = ({ images, reverseDirection }: ImageSliderProps) => {
    const screenWidth = useWindowDimensions();
    const firstChunk = useMemo(() => {
        return images.slice(0, images.length / 2);
    }, [images]);
    const secondChunk = useMemo(() => {
        return images.slice(images.length / 2, images.length);
    }, [images]);

    return (
        <MainContainer>
            <Logos>
                <LogosSlide reverseSlide={reverseDirection}>
                    {firstChunk.map((img, index) => (
                        <ImageContainer
                            screenWidth={screenWidth.width}
                            photoCount={firstChunk.length}
                            key={index}
                        >
                            <ImageMask />
                            <LogoImage
                                src={img}
                                alt={`Logo ${index}`}
                            />
                        </ImageContainer>
                    ))}
                </LogosSlide>
            </Logos>
            <SecondaryLogos>
                <SecondaryLogosSlide reverseSlide={reverseDirection}>
                    {secondChunk.map((img, index) => (
                        <ImageContainer
                            screenWidth={screenWidth.width}
                            photoCount={secondChunk.length}
                            key={index}
                        >
                            <ImageMask />
                            <LogoImage
                                src={img}
                                alt={`Logo ${index}`}
                            />
                        </ImageContainer>
                    ))}
                </SecondaryLogosSlide>
            </SecondaryLogos>
        </MainContainer>
    );
};
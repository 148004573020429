import React from "react";


export const SpotifyPlaylistView = () => {

    return (
        <iframe
            title="spotify-playlist"
            style={{ border: "none", maxWidth: "600px" }}
            src="https://open.spotify.com/embed/playlist/12gzKQEtU61BNg4GnXaWSo?utm_source=generator&theme=0"
            width="100%"
            height="400"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy">
        </iframe>
    );
};
import { api } from ".";
import { InvitedMessage } from "../../types/api/invitedMessage";

export const getMessages = () => {
    return new Promise<InvitedMessage[]>((resolve, reject) => {
        api.get("/messages")
            .then((response) => {
                const parsedData = response.data
                    .map((message: { name: string, content: string }) => {
                        return {
                            name: message.name,
                            text: message.content
                        } as InvitedMessage;
                    });
                resolve(parsedData);
            }).catch((error) => reject(error));
    });
}

export const postMessage = (message: InvitedMessage) => {
    return new Promise<void>((resolve, reject) => {
        api.post("/messages", {
            name: message.name,
            content: message.text
        }).then(() => resolve()).catch((error) => reject(error));
    });
}
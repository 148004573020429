import React, { useEffect, useState } from "react";
import { Header } from "../../components/Header";
import { MainButton } from "../../components/MainButton";
import { PaymentButtonContainer, ButtonGroupContainer, ContainerBackground, Description, GiftContainer, InfoContainer, MessageContainer, Title, FakeButtonContainer } from "./styles";
import { OutlineButton } from "../../components/OutlineButton";
import { useNavigate, useParams } from "react-router-dom";
import { Gift } from "../../types/api/gift";
import { getGiftById } from "../../services/api/gifts";
import { GiftLoader } from "../../components/GiftLoader";
import { GiftItem } from "../../components/GiftItem";
import MainInput from "../../components/MainInput";
import { GiftBuyerOrder } from "../../types/api/giftOrder";
import { GENERAL_ROUTES } from "../../constants/routes";
import { useWindowDimensions } from "../../hooks";
import { SCREEN_SIZE } from "../../constants/ScreenSizes";
import { Wallet, initMercadoPago } from "@mercadopago/sdk-react";
import { saveGiftOrder } from "../../services/api/giftOrder";
import { saveData } from "../../services/storage";
import { ORDERS_CACHE_KEY } from "../../constants/Order";


export const GiftOrder = () => {

    const { id } = useParams<{ id: string }>();
    const [currentGift, setCurrentGift] = useState<null | Gift>(null);
    const navigate = useNavigate();
    const [order, setOrder] = useState<null | GiftBuyerOrder>(null);
    const { width } = useWindowDimensions();
    const [btnIsLoading, setBtnIsLoading] = useState(false);
    const [nameError, setNameError] = useState("");


    useEffect(() => {
        if (!id) {
            navigate("/");
            return;
        }

        getGiftById(id).then((response) => {
            setOrder({
                productName: response.name,
                productId: response._id,
                price: response.price,
                preferenceId: response.preferenceId,
                buyerMessage: "",
                buyerName: ""
            })
            setCurrentGift(response);
            initMercadoPago(process.env.REACT_APP_MP_PUBLIC_KEY || "");
        }).catch((error) => {
            console.error(error);
            navigate("/");
        });
    }, [id, navigate]);

    useEffect(() => {

    })

    const sendGiftOrderToBackend = (() => {
        return new Promise<string>((resolve, reject) => {

            if(!order) {
                reject("Pedido não encontrado.");
                return;
            }

            if (order?.buyerName === "") {
                setNameError("Por favor, preencha o seu nome.");
                reject("Nome do comprador não preenchido.");
            }else{
                setNameError("");
            }

            //O Redirect é feito via Mercado Pago
            saveGiftOrder(order).then(() => {
                const currentOrder = order;
                currentOrder.orderDate = new Date().toISOString();
                const availableOrders = JSON.parse(localStorage.getItem("availableOrders") || "[]")
                saveData(ORDERS_CACHE_KEY, JSON.stringify([currentOrder, ...availableOrders]));
                resolve(order.preferenceId);
            }).catch((error) => {
                console.error(error);
                reject(error);
            });
        });
    });

    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                <Title>FINALIZAR COMPRA</Title>
                <Description>Você está presenteando os noivos com:</Description>
                <GiftContainer>
                    {
                        currentGift === null ?
                            <GiftLoader secondary customHeight={width > SCREEN_SIZE.DESKTOP ? 330 : 280} /> :
                            <GiftItem gift={currentGift} onClick={() => null} hideButton />
                    }
                </GiftContainer>
                <MessageContainer>
                    <MainInput
                        title="Nome:"
                        placeholder="Fulano da Silva"
                        onChange={(newName) => order && setOrder({ ...order, buyerName: newName })}
                        type="text"
                        value={order?.buyerName || ""}
                        secondary
                        errorText={nameError}
                    />
                    <MainInput
                        title="Deixe uma mensagem para os noivos (opcional):"
                        placeholder="Aqui você pode escrever uma mensagem muito legal que só os noivos irão ver!"
                        onChange={(newMessage) => order && setOrder({ ...order, buyerMessage: newMessage })}
                        type="text"
                        value={order?.buyerMessage || ""}
                        rowsNumber={3}
                        secondary
                    />
                </MessageContainer>
                {order &&
                    <ButtonGroupContainer>
                        <FakeButtonContainer>
                            <MainButton onClick={() => null} isLoading={btnIsLoading}>Ir para o pagamento</MainButton>
                        </FakeButtonContainer>
                        <PaymentButtonContainer>
                            <Wallet
                                onError={(error) => console.error(error)}
                                customization={{
                                    visual: {
                                        buttonBackground: "white",
                                        valuePropColor: "grey",
                                    }
                                }}
                                onReady={() => setBtnIsLoading(false)}
                                onSubmit={sendGiftOrderToBackend}
                            />
                        </PaymentButtonContainer>
                        <OutlineButton onClick={() => navigate(GENERAL_ROUTES.GIFT_LIST.route)} isLoading={btnIsLoading}>Alterar item</OutlineButton>
                    </ButtonGroupContainer>
                }
            </InfoContainer>
        </ContainerBackground>
    );
};
import styled from "styled-components";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.text};
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.primaryColor};
`;

export const GiftContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
`;

export const InfoContainer = styled.div`
    ${({ theme }) => theme.typography.secondaryText};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 50px;
`;

export const ButtonGroupContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 300px;
    margin-top: 30px;
    /* min-height: 100px; */
    position: relative;
`;

export const PaymentButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    position: absolute;
    z-index: 999;
    opacity: 0;
`;

export const FakeButtonContainer = styled.div`
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    color: ${(props) => props.theme.palette.primaryColor};
`;

export const Description = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    font-size: 16px;
    text-align: center;
    font-family: "inter-light";
    margin: 0 20px;
    color: ${(props) => props.theme.palette.primaryColor};
`;

export const MessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    width: 100%;
    max-width: 500px;
`;
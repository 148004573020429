import React from "react";
import { ContainerBackground, Description, InfoContainer, ItemsContainer, Title } from "./styles";
import { Header } from "../../components/Header";
import { AVAILABLE_HOTELS } from "../../constants/Hotels";
import { HotelCard } from "./components";


export const HotelRecommendations = () => {

    return (
        <ContainerBackground>
            <Header />
            <InfoContainer>
                <Title>RECOMENDAÇÕES DE HOSPEDAGEM</Title>
                <Description>Aproveite o momento, curta a festa <br/> e não se preocupe com a volta. <br /> <br />
                Aqui estão algumas recomendações de hotéis próximos ao local da festa pra você que mora longe.</Description>
                <br/>
                <ItemsContainer>
                    {
                        AVAILABLE_HOTELS.map((hotel, index) => {
                            return (
                                <HotelCard key={index} hotel={hotel} />
                            );
                        })
                    }
                </ItemsContainer>
            </InfoContainer>
        </ContainerBackground>
    );
};
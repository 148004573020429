import styled from "styled-components";
import { DEVICE } from "../../constants/ScreenSizes";

export const ContainerBackground = styled.div`
    background-color: ${(props) => props.theme.palette.primaryColor};
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    color: ${(props) => props.theme.palette.text};
`;

export const InfoContainer = styled.div`
    ${({ theme }) => theme.typography.secondaryText};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 130px;
    width: 100%;
    max-width: 500px;
    margin-bottom: 50px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 300px;
    margin-top: 30px;
`;

export const ItemsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @media ${DEVICE.DESKTOP} {
      min-width: 1000px;
    }
`;

export const Title = styled.h1`
    ${(props) => props.theme.typography.primaryTitle};
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
`;

export const Description = styled.p`
    ${(props) => props.theme.typography.secondaryText};
    font-size: 16px;
    text-align: center;
    font-family: "inter-light";
    margin: 0 20px;
`;
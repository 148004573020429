import React from "react";
import { RegressiveCounter } from "../../../../../../components/RegrressiveCounter";
import { AlexaImage, ButtonContainer, ContainerBackground, InfosContainer, RegressiveCounterContainer } from "./styles";
import alexa from "../../../../../../assets/images/alexa.png";
import { OutlineButton } from "../../../../../../components/OutlineButton";

interface AcceptedInfosProps {
    singleGodparent: boolean;
}


export const AcceptedInfos = ({ singleGodparent }: AcceptedInfosProps) => {

    const getThanksMessage = () => {
        if (singleGodparent) {
            return "Você aceitou o convite e já tornou nosso grande dia ainda mais especial! Obrigado por embarcar nessa com a gente! Agora é só contar os dias! 🎉💍";
        }
        return "Vocês aceitaram o convite e já tornaram nosso grande dia ainda mais especial! Obrigado por embarcarem nessa com a gente! Agora é só contar os dias! 🎉💍";
    }


    return (
        <ContainerBackground>
            <h3>{getThanksMessage()}</h3>
            <RegressiveCounterContainer>
                <RegressiveCounter />
            </RegressiveCounterContainer>
            <InfosContainer>
                <h4>DETALHES DO EVENTO</h4>
                <p>19 de julho de 2025</p>
                <p>Paróquia São Pedro Apóstolo</p>
                <p>18:00</p>
                <small>Todos os detalhes estão descritos no manual dos padrinhos</small>
                <AlexaImage src={alexa} alt="Alexa" />
                <br />
                <h4>GRUPOS</h4>
                <small>Fique por dentro de todas as informações do evento</small>
                <ButtonContainer>
                    <OutlineButton onClick={() => window.location.href = 'https://chat.whatsapp.com/BkTwIQ4gtVs4WtRO7U6uVm'}>Grupo das madrinhas</OutlineButton>
                    <OutlineButton onClick={() => window.location.href = 'https://chat.whatsapp.com/KNwGmQBl1KK2WvFFvGcKJn'}>Grupo dos padrinhos</OutlineButton>
                </ButtonContainer>
            </InfosContainer>


        </ContainerBackground>
    );
}

export const HEADER_ROUTES = {
    OUR_HISTORY: {
        id: 'our-history',
        route: '/home#our-history',
        prettyName: 'NOSSA HISTÓRIA'
    },
    EVENT_DETAILS: {
        id: 'event-details',
        route: '/home#event-details',
        prettyName: 'DETALHES DO EVENTO'
    },
    RSVP: {
        id: 'rsvp',
        route: '/home#rsvp',
        prettyName: 'CONFIRMAR PRESENÇA'
    },
    GIFT_LIST: {
        id: 'gift-list',
        route: '/home#gift-list',
        prettyName: 'LISTA DE PRESENTES'
    },
    PLAYLIST: {
        id: 'playlist',
        route: '/home#playlist',
        prettyName: 'NOSSA PLAYLIST'
    },
    SOCIAL: {
        id: 'social',
        route: '/home#social',
        prettyName: 'REDES SOCIAIS'
    },
    MESSAGES: {
        id: 'messages',
        route: '/home#messages',
        prettyName: 'MURAL DE RECADOS'
    }
};

export const GENERAL_ROUTES = {
    HOME: {
        route: '/home',
        prettyName: 'HOME'
    },
    WRITE_MESSAGE: {
        route: '/message',
        prettyName: 'Mensagens'
    },
    GIFT_LIST: {
        route: '/gift-list',
        prettyName: 'Lista de Presentes'
    },
    GIFT_ORDER: {
        route: '/gift-order',
        prettyName: ''
    },
    ORDER_SUCCESS: {
        route: '/order-status/success',
        prettyName: ''
    },
    ORDER_FAILURE: {
        route: '/order-status/failure',
        prettyName: ''
    },
    ORDER_PENDING: {
        route: '/order-status/pending',
        prettyName: ''
    },
    HOTEL_RECOMMENDATIONS: {
        route: '/hotel-recommendations',
        prettyName: 'Recomendações de Hospedagem'
    },
    RSVP: {
        route: '/rsvp',
        prettyName: 'Confirmar Presença'
    },
    GODPARENTS_INVITATION_GAME: {
        route: '/godparents-invitation-game',
        prettyName: 'Convite dos Padrinhos'
    },
    PRE_GAME_INFOS: {
        route: '/pre-game-infos',
        prettyName: 'Pré-jogo'
    },
    POST_GAME_INFOS: {
        route: '/post-game-infos',
        prettyName: 'Pós-jogo'
    }
};
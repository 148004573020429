import React from "react";
import { InvitedMessage } from "../../../../../../types/api/invitedMessage";
import { CarouselItemContainer, MessageContainer } from "./styles";

interface MessageCarouselItemProps {
    message: InvitedMessage;
}

export const MessageCarouselItem = ({ message }: MessageCarouselItemProps) => {

    return (
        <CarouselItemContainer>
            <MessageContainer>
                <h3>{message.text}</h3>
                <p>{message.name}</p>
            </MessageContainer>
        </CarouselItemContainer>
    );
}
import { api } from ".";
import { InvitedFamily } from "../../types/api/invitedFamily";

export const getRsvpById = (id: string) => {
    return new Promise<InvitedFamily>((resolve, reject) => {
        api.get(`/familyInvitations?id=${id}`).then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}

export const getRsvpByName = (name: string) => {
    return new Promise<InvitedFamily>((resolve, reject) => {
        api.get(`/familyInvitations?inviteName=${name}`).then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}

export const getAllRsvps = () => {
    return new Promise<InvitedFamily[]>((resolve, reject) => {
        api.get("/familyInvitations").then((response) => resolve(response.data)).catch((error) => reject(error));
    });
}
import React from "react";
import ContentLoader from "react-content-loader";
import { Container } from "./styles";
import { useTheme } from "styled-components";
import { useWindowDimensions } from "../../hooks";
import { SCREEN_SIZE } from "../../constants/ScreenSizes";

interface GiftLoaderProps {
    secondary?: boolean;
    customHeight?: number;
}

export const GiftLoader = ({secondary, customHeight} : GiftLoaderProps) => {

    const theme = useTheme();
    const {width} = useWindowDimensions();
    const cardHeight = customHeight ? customHeight : width < SCREEN_SIZE.DESKTOP ? 330 : 400;


    return (
        <Container>
            <ContentLoader
                speed={2}
                width={width < SCREEN_SIZE.DESKTOP ? 170 : 300}
                height={cardHeight}
                viewBox={`0 0 ${width < SCREEN_SIZE.DESKTOP ? 170 : 300} ${cardHeight}`}
                backgroundColor={secondary ? theme.palette.primaryColor : theme.palette.text}
                foregroundColor={secondary ? theme.palette.secondaryColor : theme.palette.textSecondary}
            >
                <rect x="8" y="8" rx="3" ry="3" width={width < SCREEN_SIZE.DESKTOP ? 160 : 290} height={cardHeight} />
            </ContentLoader>
        </Container>

    )
}